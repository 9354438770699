<template>
    <div class="vx-col md:w-full sm:w-auto text-center">
        <ul style="display: flex; align-items: center; justify-content: center;">
            <li class="mr-5">
                <vs-switch v-model="statusCamera">
                    <span slot="on">Bật</span>
                    <span slot="off">Tắt</span>
                </vs-switch>
            </li>
            <li class="mr-2">
                <vx-tooltip text="Chỉnh sửa" position="top">
                    <feather-icon icon="EditIcon" class="action-success"
                                  svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                                  @click="editRecord"/>
                </vx-tooltip>
            </li>
            <li class="mr-2">
                <vx-tooltip text="Cài đặt thông báo" position="top">
                    <feather-icon icon="SettingsIcon" class="action-success ml-2"
                                  svgClasses="h-5 w-5 cursor-pointer"
                                  @click="settingNotification"/>
                </vx-tooltip>
            </li>
            <li>
                <vx-tooltip text="Hủy bỏ" position="top">
                    <feather-icon icon="TrashIcon" class="action-err ml-2"
                                  svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                                  @click="confirmDeleteRecord"/>
                </vx-tooltip>
            </li>
        </ul>
        <Notification :openPopup="showPopupNotify" :closePopup="closePopup"></Notification>
    </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Notification from "../my-cameras/Notification";

export default {
    name: 'CellRendererActions',
    components: {VuePerfectScrollbar, Notification},
    data() {
        return {
            status: true,
            showPopupNotify: false
        }
    },
    created() {
        if (this.params.data.evgCamera.streamingStatus === 1) {
            this.status = true;
        } else {
            this.status = false;
        }
    },
    computed: {
        statusCamera: {
            get() {
                return this.status;
            },
            set() {
                if (this.status) {
                    this.disconnectCamera()
                } else {
                    this.connectCamera()
                }
            }
        }
    },
    methods: {
        closePopup(){
            this.showPopupNotify = false;
        },
        settingNotification() {
            this.camera = this.params.data;
            this.$eventBus.$emit('notify-camera', this.camera);
            this.showPopupNotify = true;
        },
        editRecord() {
            let branchId = this.params.data.organizationBranchId;
            this.$router.push(`/user/edit-camera?id=${this.params.data.id}${branchId ? `&branch_id=${branchId}` : ''}`).catch(() => {
            })
        },
        showCamera() {
            let branchId = this.params.data.organizationBranchId;
            this.$router.push(`/user/manage-camera/show?id=${this.params.data.id}&branch_id=${branchId}`).catch(() => {
            })
        },
        confirmDeleteRecord() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận xoá`,
                text: `Bạn có chắc là muốn xoá camera "${this.params.data.cameraName}" không ?`,
                accept: this.deleteRecord,
                acceptText: "Xoá",
                cancelText: "Huỷ"
            })
        },
        deleteRecord() {
            this.$vs.loading();
            this.$crm.post(`/camera/delete/${this.params.data.id}`).then(() => {
                this.$vs.loading.close();
                return this.showDeleteSuccess();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showDeleteSuccess() {
            this.$store.commit('DELETE_CAMERA_STATUS', true);
            this.$vs.notify({
                color: 'success',
                text: 'Xoá camera thành công.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        },
        showConnectSuccess() {
            this.$store.commit('DELETE_CAMERA_STATUS', true);
            this.$vs.notify({
                color: 'success',
                text: 'Kết nối camera thành công.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        },
        connectCamera() {
            this.$vs.loading();
            this.$crm.post(`/camera/connect/${this.params.data.id}`).then(() => {
                this.$vs.loading.close();
                return this.showConnectSuccess();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showDisconnectSuccess() {
            this.$store.commit('DELETE_CAMERA_STATUS', true);
            this.$vs.notify({
                color: 'success',
                text: 'Ngắt kết nối camera thành công.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        },
        disconnectCamera() {
            this.$vs.loading();
            this.$crm.post(`/camera/disconnect/${this.params.data.id}`).then(() => {
                this.$vs.loading.close();
                return this.showDisconnectSuccess();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
}
</script>
