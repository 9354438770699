<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params)">
        <span>{{ organizationTypeString(params) }}</span>
    </vs-chip>
</template>

<script>
    export default {
        name: 'CellRendererStatus',
        computed: {
            chipColor() {
                return value => {
                    if (value.data.evgCamera.streamingStatus === 0) {
                        return "danger";
                    } else if (value.data.evgCamera.streamingStatus === 1) {
                        return "success";
                    } else {
                        return "warning";
                    }
                }
            },
            organizationTypeString() {
                return value => {
                    if (value.data.evgCamera.streamingStatus === 0) {
                        return "Ngừng hoạt động";
                    } else if (value.data.evgCamera.streamingStatus === 1) {
                        return "Đang hoạt động";
                    } else {
                        return "Đang chờ";
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scpoped>
    .ag-grid-cell-chip {
        &.vs-chip-success {
            background: rgba(var(--vs-success), .15);
            color: rgba(var(--vs-success), 1) !important;
            font-weight: 500;
        }

        &.vs-chip-danger {
            background: rgba(var(--vs-danger), .15);
            color: rgba(var(--vs-danger), 1) !important;
            font-weight: 500;
        }

        &.vs-chip-warning {
            background: rgba(var(--vs-warning), .15);
            color: rgba(var(--vs-warning), 1) !important;
            font-weight: 500;
        }
    }
</style>
